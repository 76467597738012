import { BASE_URL } from '../../ApiBaseUrl';

// STORE USER GET ALL
export const STORE_USER_GETALL = async (modeid: any) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		const response = await fetch(
			`${BASE_URL}/users_stores/getAll//${modeid}?page_size=100&page_number=1`,
			options,
		);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log('error', error);
	}
};
