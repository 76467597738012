import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	user: string;
	setUser?: React.Dispatch<React.SetStateAction<string>>;
	setUserData: (data: any) => void;
	// setUserData?: React.Dispatch<React.SetStateAction<{}>>;

	userData: Partial<IUserProps>;
  }
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode; 
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');
	// const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const [userData, setUserData] = useState<any>({token: localStorage.getItem('token')} || {});
    
	
	useEffect(() => {	
		localStorage.setItem('facit_authUsername', user);
		// localStorage.setItem('userData', JSON.stringify(userData));
	  }, [user]);
     
	// useEffect(() => {
	// 	if (user !== '') {
	// 		setUserData(getUserDataWithUsername(user));
	// 	} else {
	// 		setUserData({});
	// 	}
	// }, [user]);

	const value = useMemo(
		() => ({
		  user,
		  setUser,
		  setUserData,
		  userData,
		}),
		[user, setUser, setUserData, userData]
	  );
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
