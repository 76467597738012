import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	Create_restaurant,
	Edit_restaurant,
	GetAll_Restaurant,
	GetById_Restanurant,
	delete_restaurant,
	menu_add,
	upload_image,
} from './RestaurantApi';
import { add_menu_All } from '../../Menu/MenuSlice/Menu_Slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOCAL_USER } from '../../../../utils/localStorageData';

interface RestaurantState {
	allRestaurant: [];
	currentRestaurant: [];
}
const initialState: RestaurantState = {
	allRestaurant: [],
	currentRestaurant: [],
};

// ****************get all*********************
const fetchRestaurantAll = createAsyncThunk(
	'restaurant/fetchRestaurantAll',
	async (modeid: any) => {
		try {
			const response = await GetAll_Restaurant(modeid);
			console.log(response);
			return response;
		} catch (error) {
			console.log(error);
		}
	},
);
// ****************************get by id**********************
const fetch_Restaurant_ById = createAsyncThunk(
	'restaurant/fetch_Restaurant_ById',
	async (id: any) => {
		try {
			const response = await GetById_Restanurant(id as any);
			return response;
			console.log(response);
		} catch (error) {
			console.log(error);
		}
	},
);
// ***************************create restaurant**********************

const add_restaurant = createAsyncThunk('restaurant/addrestaurant', async (payload: any) => {
	const { data, mainImg } = payload;
	console.log('data slice', data);
	// console.log(data.menus)
	const modeid = LOCAL_USER.mode;
	try {
		const response = await Create_restaurant(data);
       
		console.log(response);
		return response;
		const menuData = { ...data.menus, store: response.data._id };
		console.log(menuData);
		if (response.statusCode === 201) {
            const id = response.data._id
            console.log('======>>>>>id slice',id)
            debugger
			upload_image(id, mainImg);
			await menu_add(menuData);

			GetAll_Restaurant(modeid);

			return response.data;
		}
	} catch (error) {
		console.log(error);
	}
});
const edit_restaurant = createAsyncThunk('restaurant/edit_restaurant', async (dataa: any) => {
	console.log(dataa);
	const { data, id, image } = dataa;
	console.log(image);
	const modee = localStorage.getItem('modeid');

	try {
		const response = await Edit_restaurant(data, id as any);

		if (response?.ok as any) {
			await GetAll_Restaurant(modee);
			return response;
		}
	} catch (error) {
		console.log(error);
	}
});

const delete_restaurant_id = createAsyncThunk('driver/delete_restaurant_id', async (id: any) => {
	const mode = localStorage.getItem('modeid');
	try {
		const response = await delete_restaurant(id as any);
		console.log(response);
		if (response?.statusCode === 200) {
			await GetAll_Restaurant(mode as any);
		}
		// return response
	} catch (error) {
		console.log(error);
	}
});

const RestaurantSlice = createSlice({
	name: 'RestaurantSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchRestaurantAll.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			console.log(action.payload);
			state.allRestaurant = action.payload;
		});

		builder.addCase(fetch_Restaurant_ById.fulfilled, (state, action: any) => {
			console.log(action.payload, 'payload');
			state.currentRestaurant = action.payload;
		});
	},
});

export default RestaurantSlice.reducer;
export {
	fetchRestaurantAll,
	fetch_Restaurant_ById,
	add_restaurant,
	delete_restaurant_id,
	edit_restaurant,
};
