import { BASE_URL } from "../../../../ApiBaseUrl";





// *********************************gett all*****************************************/
export const GetAll_Restaurant=async(modeid:any)=>{
    const options={
        method:"GET",
        headers:{
          'Content-Type':'application/json',
        },
    }
    try {
        const response=await fetch(`${BASE_URL}stores/getAll/${modeid}?page_no=1&page_size=100`,options)
        const data=await response.json()
        console.log(data.data)
        // debugger
        return data.data
    } catch (error) {
        console.log(error)
    }
}


// *********************************get by id***************************************/

 export  const GetById_Restanurant=async(id:any)=>{
    const options={
        method:'GET',
        headers:{
            'Content-Type':'application/json',
        },
    }
    // debugger
    try {
        const response=await fetch(`${BASE_URL}stores/getById/${id}`,options)
        const data=await response.json()
        return data.data
    } catch (error) {
        console.log(error)
    }
 } 


//  **************** create restaurant*********************/

export const Create_restaurant=async(data:any)=>{
    const options={
        method:'POST',
        headers:{
            'content-type': 'application/json',
        },
        body:JSON.stringify(data)

     
    }
 
    try {
        const response = await fetch(`${BASE_URL}stores/create`,options)
        const datas = await response.json();
        return datas;
    } catch (error) {
        console.log(error)
    }
}
// *****************************edir restaurant*****************************
export const Edit_restaurant = async ( data: any,id:any) => {
	

	debugger;
	console.log(data)
	const options = {
		method: 'PUT',
        headers: {
			'content-type': 'application/json',
			
		},
		body: JSON.stringify(data),
	};

	try {
		const response = await fetch(`${BASE_URL}stores/update/${id}`, options);
		return response
	} catch (error) {
		console.log(error)
	}
};

// ************upload prifile**********

export const upload_image=async(id:any , image:any)=>{
    debugger
    console.log("========>>>>>>_id api", id)
    const formData = new FormData();
    formData.append("image", image);
    const options={
        method:'POST',
        body:formData,
}
try {
    const response=await fetch(`${BASE_URL}stores/uploadImage/${id}`,options)
    return response
} catch (error) {
    console.log(error)
}
}

// ********************************************************Driver deleter**************************
export const delete_restaurant = async (id: any) => {
	const options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const response = await fetch(`${BASE_URL}stores/remove/${id}`, options);
		const data = await response.json();
		return data;
	} catch (error) {
		console.log(error);
	}
};

export const menu_add = async ( data: any) => {


	
	const options = {
		method: 'POST',
		body: JSON.stringify(data),
        headers:{
            "Content-Type":"application/json"

        }
	};
	try {
		const response = await fetch(`${BASE_URL}menu/create`, options);
		return response
	} catch (error) {
		console.log(error)
	}
};