import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { customerGetByIdApi } from '../API/Customer';

interface CustomerState {
	loading: boolean;
	customer: any; // Adjust the type according to your customer data structure
	error: string | null;
}

const initialState: CustomerState = {
	loading: true,
	customer: null,
	error: null,
};

export const customerGetByIdSlice = createAsyncThunk('customer/getById', async (id: any) => {
	try {
       const response = await customerGetByIdApi(id);
       return response?.data;
	} catch (error) {
        console.log(error);
        throw error; // Throw the error so it's captured by the rejected action
    }
});

const customerSlice = createSlice({
    name: 'customer',
	initialState,
	reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(customerGetByIdSlice.pending, (state : any) => {
            state.loading = true;
        })
        .addCase(customerGetByIdSlice.fulfilled, (state, action : any) => {
            state.loading = false;
            state.customer = action.payload; 
            state.error = null;
        })
        .addCase(customerGetByIdSlice.rejected, (state, action :  any) => {
            state.loading = false;
            state.error = action.error.message;
        });
    }
});

export default customerSlice.reducer;
