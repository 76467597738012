import { BASE_URL } from "../../../../../ApiBaseUrl";
import showNotification from "../../../../../components/extras/showNotification";
import Icon from "../../../../../components/icon/Icon";

// get allitems

export const getAll_Category=async(modeid:any)=>{
    const options={
        method:'GET',
        headers:{
            'Content-Type':'application/json'
        }
    }
    try {
        const response=await fetch(`${BASE_URL}/categories/getAll/${modeid}?page_no=1&page_size=100`,options)
        const data= await response.json()
        console.log(data.data)
        return data.data
    } catch (error) {
        console.log(error,"error")
    }
}

export const create_category = async ( data: any) => {
    const formData: any = new FormData();
	formData.append('fullname', data.fullname);
	formData.append('description', data.description);
	formData.append('avatar', data.avatar);
	formData.append('is_activated', data.is_activated);
	formData.append('note', data.note);
	
	formData.append('admin_note', data.admin_note);
	formData.append('mode', data.mode);

	
	console.log(JSON.stringify(formData));

	
	const options = {
		method: 'POST',
		body: formData,
	};

	try {
		const response = await fetch(`${BASE_URL}/categories/create`, options);
		return await response.json()
	} catch (error) {
		console.log(error)
	}
};

//*****************************************// ITEM_Edit********************************** */ 

export const category_Edit = async ( data: any,id:any) => {
	

	debugger;
	console.log(data)
	const options = {
		method: 'PUT',
		headers:{
            'Content-Type':'application/json'
        },
		body: JSON.stringify(data),
	};

	try {
		const response = await fetch(`${BASE_URL}/categories/updateCategory/${id}`, options);
		return response
	} catch (error) {
		console.log(error)
	}
};
export const Category_delete = async (id: any) => {
	const options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const response = await fetch(`${BASE_URL}/categories/deleteCategory/${id}`, options);
	
		const data = await response.json();
		console.log(data)
		if(data?.statusCode){
			// getAll_Category()
		}
		return data;
	} catch (error) {
		console.log(error);
	}
};

export const  CategorySearch=async(name:any)=>{
	const options={
		method:'GET',
		headers:{
			'Content-Type':'application/json',
		},
		
	}

	try {
			const response=await fetch(`${BASE_URL}/categories/search?name=${name}`,options)
			const data=await response.json()
			console.log(data.data)
			return data.data.message
	} catch (error) {
		console.log(error)
	}
}