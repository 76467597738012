import { BASE_URL } from '../../../../ApiBaseUrl';

export const Mode_all = async () => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		const response = await fetch(
			`${BASE_URL}/modes/getall?page_number=1&page_size=100`,
			options,
		);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log('error', error);
	}
};

// add

export const Mode_add = async (value: any) => {
	console.log(value);
	const options = {
		method: 'POST',
		headers: {
            'Content-Type': 'application/json',
		},
        body: JSON.stringify(value),
	};

	try {
		const response = await fetch(`${BASE_URL}/modes/create`, options);
		return response;
	} catch (error) {
		console.log(error);
	}
};

// edit
export const Mode_Edit = async (value: any, id: any) => {
	console.log(value);
    debugger
	const options = {
		method: 'PUT',
		headers: {
            'Content-Type': 'application/json',
		},
        body: JSON.stringify(value),
	};

	try {
		const response = await fetch(`${BASE_URL}/modes/updateMode/${id}`, options);
		return response;
	} catch (error) {
		console.log(error);
	}
};
export const Mode_delete = async (id: any) => {
	const options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const response = await fetch(`${BASE_URL}/modes/deleteMode/${id}`, options);
	
		const data = await response.json();
		console.log(data)
		
		return data;
	} catch (error) {
		console.log(error);
	}
};