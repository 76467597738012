import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STORE_USER_GETALL } from '../API/STORE_USER';

interface StoreUserState {
    loading: boolean;
    storeUser: any[]; 
    error: string | null;
}

const initialState: StoreUserState = {
    loading: false,
    storeUser: [],
    error: null,
};

//********************** */ get all
const fetchAllStoreUser = createAsyncThunk(
    'storeUser/fetchAllStoreUser',
    async (modeid: any, { rejectWithValue }) => {
        try {
            const response = await STORE_USER_GETALL(modeid);
            return response;
        } catch (error) {
            return rejectWithValue(error instanceof Error ? error.message : 'Unknown error occurred');
        }
    },
);


const storeUserSlice = createSlice({
    name: 'storeUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllStoreUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllStoreUser.fulfilled, (state, action) => {
                state.loading = false;
                state.storeUser = action.payload;
            })
            .addCase(fetchAllStoreUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string; 
            });
    },
});

export { fetchAllStoreUser };
export default storeUserSlice.reducer;
