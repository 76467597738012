import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { customerGet_id, customer_GetAll } from "./CustomerApi";


const initialState={
 all_customer:[],
 current_customer:[]
}
//********************** */ get all
const fetch_all_customer=createAsyncThunk(
    'customer/fetch_all_customer',
    async(modeid:any)=>{
        try {
            const response= await customer_GetAll(modeid)
          console.log(response)
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )

 const fetch_customerBy_id = createAsyncThunk('driver/driverView', async (id:any) => {
	const response = await customerGet_id(id);
	console.log(response);
	return response; // Assuming the response is an array of drivers
});



const Customer_slice = createSlice({
	name: 'customer',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetch_all_customer.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			state.all_customer=action.payload
		});
		
        builder.addCase(fetch_customerBy_id.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			state.current_customer=action.payload
		});
	},
});

export {fetch_all_customer,fetch_customerBy_id}
export default Customer_slice.reducer