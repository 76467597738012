import { BASE_URL } from "../../../../ApiBaseUrl";


export const customer_GetAll = async (modeid:any) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			
		},
	};

	try {
		const response = await fetch(
			`${BASE_URL}/customers/getall/${modeid}?page_size=100&page_number=1`,
			options,
		);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log('error', error);
	}
};
// get customter by id
export const customerGet_id = async ( id: string) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			
		},
	};
 debugger
	try {
		const response = await fetch(`${BASE_URL}/customers/getById/${id}`, options);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error);
	}
};
