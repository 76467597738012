import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { USER_GETALL } from '../API/USER';

interface UserState {
    loading: boolean;
    User: any[]; 
    error: string | null;
}

const initialState: UserState = {
    loading: false,
    User: [],
    error: null,
};

//********************** */ get all
const fetchAllUser = createAsyncThunk(
    'User/fetchAllUser',
    async (modeid: any, { rejectWithValue }) => {
        try {
            const response = await USER_GETALL(modeid);
            return response;
        } catch (error) {
            return rejectWithValue(error instanceof Error ? error.message : 'Unknown error occurred');
        }
    },
);


const UserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllUser.fulfilled, (state, action) => {
                state.loading = false;
                state.User = action.payload;
            })
            .addCase(fetchAllUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string; 
            });
    },
});

export { fetchAllUser };
export default UserSlice.reducer;
