import { BASE_URL } from "../../../../../ApiBaseUrl";


// get allitems

export const getAll_Item = async (modeid:any) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		const response = await fetch(`${BASE_URL}items/getAll/${modeid}?page_no=1&page_size=111`,options);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log('error', error);
	}
};

// create
export const create_item = async ( data: any) => {
    const formData: any = new FormData();
	formData.append('itemName', data.itemName);
	formData.append('menu', data.menu);
	formData.append('mode',data.mode)
	formData.append('category', data.category);
	formData.append('status', data.status);
	formData.append('itemDsc', data.itemDsc);
	formData.append('avatar', data.avatar);
	formData.append('price', data.price);
	formData.append('vegetarian_type', data.vegetarian_type);
	formData.append('is_activated', data.is_activated);
	formData.append('quantity', data.quantity);



	console.log(JSON.stringify(formData));

	
	const options = {
		method: 'POST',
		body: formData,
	};

	try {
		const response = await fetch(`${BASE_URL}/items/create`, options);
		return response
	} catch (error) {
		console.log(error)
	}
};
export const itemGetid = async (id: string) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',

		},
	};

	try {
		const response = await fetch(`${BASE_URL}/items/getById/${id}`, options);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log(error);
	}
};


// ********************************************************Driver deleter**************************
export const item_delete = async (id: any) => {
	const options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const response = await fetch(`${BASE_URL}/items/delteItem/${id}`, options);
		const data = await response.json();
		return data;
	} catch (error) {
		console.log(error);
	}
};

//*****************************************// ITEM_Edit********************************** */ 

export const ITEM_Edit = async ( data: any,id:any) => {
	

	debugger;
	console.log(data)
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	};

	try {
		const response = await fetch(`${BASE_URL}/items/updateItem/${id}`, options);
		return response
	} catch (error) {
		console.log(error)
	}
};
// *******************************search api*******************************//

export const  itemSearch=async(name:any)=>{
	const options={
		method:'GET',
		headers:{
			'Content-Type':'application/json',
		},
		
	}

	try {
			const response=await fetch(`${BASE_URL}/items/search?name=${name}`,options)
			const data=await response.json()
			console.log(data.data)
			return data.data
	} catch (error) {
		console.log(error)
	}
}
export const  itemFilter=async(name:any)=>{
	const options={
		method:'GET',
		headers:{
			'Content-Type':'application/json',
		},
		
	}

	try {
			const response=await fetch(`${BASE_URL}/items/filter?is_active=${name.includes('true')}&is_inactive=${name.includes('false')}&sort_order=ascending`,options)
			const data=await response.json()
			console.log(data.data)
			return data.data
	} catch (error) {
		console.log(error)
	}
}