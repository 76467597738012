import axios from "axios"
import { BASE_URL } from "../../ApiBaseUrl"



export const customerGetByIdApi = async (id: any) => {
    try {
        const response = await axios.get(`${BASE_URL}customers/getById/${id}?page_size=100&page_number=1`)
        return response
    } catch (error) {
        console.log(error)
    }
}