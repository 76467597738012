import { BASE_URL } from '../../ApiBaseUrl';

//  USER GET ALL
export const USER_GETALL = async (modeid: any) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		const response = await fetch(
			`${BASE_URL}User/getAll/${modeid}?page_size=100&page_number=1`,
			options,
		);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log('error', error);
	}
};
