import { BASE_URL } from "../../../../ApiBaseUrl";
// *********************************gett all*****************************************/
const modeid=localStorage.getItem('modeid')
	console.log(modeid)
export const GetAll_Order=async(token:any)=>{
    const options={
        method:"GET",
        headers:{
          'Content-Type':'application/json',
          'x-access-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGYwNmNhMDMxOWUzNDIzOTI5NzAzYjQiLCJpYXQiOjE2OTM0NzgzMzh9.wzfeZr5ZyMYoQIRV4QLcK1R0DLO8zTJgbkiMvQqI8zA'
        },
    }
  debugger
    console.log(token)
    try {
        const response=await fetch(`${BASE_URL}order/getall/${modeid}?page_no=1&page_size=100`,options)
        const data=await response.json()
        console.log(data)
        return data.data
    } catch (error) {
        console.log(error)
    }
}

// **************************order get by id****************************


export const create_order= async ( data: any) => {
   

	const options = {
		method: 'POST',
        headers:{
            'Content-Type':'application/json'
        },
		body: JSON.stringify(data),
	};
debugger
	try {
		const response = await fetch(`${BASE_URL}order/create`, options);
		return await response.json()
	} catch (error) {
		console.log(error)
	}
};

// ********************************************************Driver deleter**************************
export const Order_delete = async (data:any) => {
    const{token,deleteId}=data
	console.log(token)
	console.log(deleteId)

	const options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
            'x-access-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGYwNmNhMDMxOWUzNDIzOTI5NzAzYjQiLCJpYXQiOjE2OTM0NzgzMzh9.wzfeZr5ZyMYoQIRV4QLcK1R0DLO8zTJgbkiMvQqI8zA'
		},
	};
     debugger
	try {
		const response = await fetch(`${BASE_URL}order/softDelete/${deleteId}`, options);
		const dataa = await response.json();
		return dataa;
	} catch (error) {
		console.error(error);  // Changed to use console.error for better visibility of errors
        throw error; 
	}
};

export const Order_Viewid = async (data:any) => {
	const{token,id}=data

	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			'x-access-token': token,
		},
	};
	try {
		const response = await fetch(`${BASE_URL}order/getById/${id}`, options);
		const dataa = await response.json();
		// console.log(data.data);
		return dataa.data;
	} catch (error) {
		console.log(error);
	}
};


export const  Order_Search=async(name:any)=>{
	const options={
		method:'GET',
		headers:{
			'Content-Type':'application/json',
		},
		
	}

	try {
			const response=await fetch(`${BASE_URL}order/search?name=${name}`,options)
			const data=await response.json()
			console.log(data.data)
			return data.data
	} catch (error) {
		console.log(error)
	}
}